import React from 'react';
import './persistorHelpers.scss';

export const Loading = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
      <p>Loading...</p>
    </div>
  );
};

export const onBeforeLift = () => {
  console.log('Lifting store from the session storage...');
};
